import { Category, Computer, Forum, Inventory, LocalOffer, Person, Storefront } from "@mui/icons-material"

// *Roles IDs
// 0 and 1 for Admin
// 2 for Product Manger
// 3 for Accountant
// 4 for Community Manager
// 5 for Order Manager

const Navigations = [
  {
    label: 'Dashboard',
    icon: <Computer />,
    path: '/'
  },
  {
    label: 'Product Management',
    role: [0, 1, 2],
    icon: <Storefront />,
    children: [
      {
        label: 'Tags',
        icon: <LocalOffer />,
        path: '/product-management/tags'
      },
      {
        label: 'Types',
        icon: <Inventory />,
        path: '/product-management/types'
      },
      {
        label: 'Variations',
        icon: <Storefront />,
        path: '/product-management/variations'
      },
      {
        label: 'Categories',
        icon: <Category />,
        path: '/product-management/categories'
      },
      {
        label: 'Subcategories',
        icon: <Category />,
        path: '/product-management/subcategories'
      },
      {
        label: 'Products',
        icon: <Category />,
        path: '/product-management/products'
      },
      {
        label: 'Featured Products',
        icon: <Category />,
        path: '/product-management/featured-products'
      },
      {
        label: 'Product Approvals',
        icon: <Category />,
        path: '/product-management/product-approvals'
      },
      {
        label: 'Banned Products',
        icon: <Category />,
        path: '/product-management/banned-products'
      },
    ]
  },
  {
    label: 'Payment Management',
    role: [0, 1],
    icon: <Storefront />,
    children: [
      {
        label: 'Payment Requests',
        icon: <LocalOffer />,
        path: '/payment-management/payment-requests'
      },
      {
        label: 'Buyer To Admin Payment',
        icon: <LocalOffer />,
        path: '/payment-management/buyer-admin-payments'
      },
      {
        label: 'Admin To Seller Payment',
        icon: <LocalOffer />,
        path: '/payment-management/admin-seller-payments'
      },
      {
        label: 'Receivables',
        icon: <LocalOffer />,
        path: '/payment-management/receivables'
      },
    ]
  },
  {
    label: 'User Management',
    role: [0, 1],
    icon: <Storefront />,
    children: [
      {
        label: 'Admin Users',
        icon: <LocalOffer />,
        path: '/user-management/users'
      },
      {
        label: 'Manufacturers',
        icon: <LocalOffer />,
        path: '/user-management/manufacturers'
      },
      {
        label: 'Drop Shipper',
        icon: <LocalOffer />,
        path: '/user-management/dropshippers'
      },
      {
        label: 'Suppliers',
        icon: <LocalOffer />,
        path: '/user-management/suppliers'
      },
      {
        label: 'Users Membership',
        icon: <LocalOffer />,
        path: '/user-management/users-membership'
      },
    ]
  },
  {
    label: 'Tax Management',
    role: [0, 1],
    icon: <Storefront />,
    children: [
      // {
      //   label: 'Vat',
      //   icon: <Storefront />,
      //   path: '/tax-management/vat'
      // },
      {
        label: 'Tax Types',
        icon: <LocalOffer />,
        path: '/tax-management/tax-types'
      }
    ]
  },
  {
    label: 'Fulfillment',
    role: [0, 1],
    icon: <Storefront />,
    path: '/fulfillment'
  },
  {
    label: 'Refunds',
    role: [0, 1],
    icon: <Storefront />,
    path: '/refunds'
  },
  {
    label: 'Brand Management',
    role: [0, 1],
    icon: <Storefront />,
    path: '/brand-management'
  },
  {
    label: 'Email Marketing',
    role: [0, 1],
    icon: <Storefront />,
    path: '/email-marketing'
  },
  {
    label: 'Commission',
    role: [0, 1],
    icon: <Storefront />,
    path: '/commission'
  },
  {
    label: 'Sales',
    role: [0, 1],
    icon: <Storefront />,
    path: '/sales'
  },
  {
    label: 'Revenues',
    role: [0, 1],
    icon: <Storefront />,
    path: '/revenues'
  },
  {
    label: 'Vouchers',
    role: [0, 1],
    icon: <Storefront />,
    path: '/vouchers'
  },
  {
    label: 'Sale Management',
    role: [0, 1],
    icon: <Storefront />,
    children: [
      {
        label: 'Campaigns',
        icon: <LocalOffer />,
        path: '/sale-management/campaigns'
      },
      {
        label: 'Flash Sales',
        icon: <Inventory />,
        path: '/sale-management/flash-sales'
      },
    ]
  },
  {
    label: 'Order Management',
    role: [0, 1, 5],
    icon: <Inventory />,
    children: [
      {
        label: 'Orders',
        icon: <Inventory />,
        path: '/order-management/orders'
      },
      {
        label: 'Fulfillment Orders',
        icon: <Inventory />,
        path: '/order-management/fulfillment-orders'
      },
    ]
  },
  {
    label: 'Invoice Management',
    role: [0, 1],
    icon: <Inventory />,
    path: '/invoice-management'
  },
  {
    label: 'Courier Management',
    role: [0, 1],
    icon: <Inventory />,
    path: '/courier-management'
  },
  {
    label: 'Website Management',
    role: [0, 1],
    icon: <Inventory />,
    path: '/website-management'
  },
  {
    role: [0, 1],
    label: 'Chat',
    icon: <Forum />,
    path: '/chat'
  },
  {
    label: 'Profile',
    icon: <Person />,
    path: '/profile'
  },
]

export default Navigations